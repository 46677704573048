<template>
  <div>
    <div
    class="innerContent"
    style="
      height: calc(100vh);
      width: 100%;
      min-width: 85%;
      max-width: 100%;
      background: var(--v-greyRaised-base) !important;
    "
  >
      <v-toolbar flat dense>
      <v-spacer></v-spacer>
        <v-chip class="mr-1 searchChipWrap" style="max-width:550px">
          <v-btn color="primary" small icon @click="addProduct">
            <v-icon small>add_circle_outline</v-icon>
          </v-btn>
        <v-icon small color="grey" class="ml-1">search</v-icon>
          <v-text-field
            placeholder="Search"
            class="mb-1 searchInput"
            hide-details
            rounded
            clearable
            dense
            style="margin-top: 2px; width: 300px"
            v-model="search"
          ></v-text-field>

          <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              right
              small
              icon
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
             icon
              right
              small
              @click="load()"
              v-on="on"
            >
              <v-icon small>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Products & Services</span>
        </v-tooltip>
       
        </v-chip>
    </v-toolbar>
        <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="false"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitCellContents',
        defaultMinWidth: 150,
        columnLimits: [
          {colId: 'description',
          width: 400,
          maxWidth: 500,
          wrapText: true,
          autoHeight: true

          }
        ],
      }"
      style="width: 100%; height: 92%"
      :rowSelection="'multiple'"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :undoRedoCellEditing="true"
      :undoRedoCellEditingLimit="25"
      :columnDefs="columns"
      :rowData="productServices"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef.def"
      :getContextMenuItems="getContextMenuItems"
      :sideBar="sidebar"
      :statusBar="statusBar"
      :initialState="initialState"
      :getRowId="getRowId"
      @state-updated="onStateUpdated"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
    </div>

    <v-dialog v-model="productModal" width="800px">
      <v-card
      style="
        background-color: rgba(0, 0, 0, 0.5) !important;
        background: rgba(0, 0, 0, 0.4) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      "
      >
        <v-toolbar flat dense style="background: transparent">
          <v-toolbar-title style="font-size: 16px">
            <span v-if="product && product.id"> Edit </span>
            <span v-else> Add </span>
            Product
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="saveProduct"
            :disabled="!product.productServiceCategoryId || !product.productServiceSubcategoryId || !product.code || !product.name"
            :loading="savingProduct"
            color="primary"
          >
            <v-icon small>save</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="product && product.id"
            @click="deleteProduct(product)"
            :loading="deletingProduct"
            color="red"
          >
            <v-icon small>delete</v-icon>
          </v-btn>
          <v-btn @click="productModal = false" color="redPop" icon
            ><v-icon small>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="my-0 py-0">
          <v-container style="width: 100%">
            <v-row>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-list dense class="my-0 py-0" >

                  <v-autocomplete
                    dense
                    outlined
                    v-model="product.productServiceCategoryId"
                    hide-details
                    class="my-1"
                    clearable
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    @change="product.productServiceSubcategoryId=null"
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Category* </span>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    dense
                    outlined
                    :disabled="!product.productServiceCategoryId"
                    v-model="product.productServiceSubcategoryId"
                    hide-details
                    class="my-1"
                    clearable
                    :items="filteredSubcategories()"
                    item-text="name"
                    item-value="id"
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Subcategory* </span>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    dense
                    outlined
                    v-model="product.code"
                    hide-details
                    class="my-1"
                    clearable
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Product Code* </span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    v-model="product.name"
                    hide-details
                    class="my-1"
                    clearable
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Name* </span>
                    </template>
                  </v-text-field>

                  <v-text-field
                    dense
                    outlined
                    v-model="product.url"
                    hide-details
                    class="my-1"
                    clearable
                  >
                    <template v-slot:label>
                      <span style="font-size: 13px"> Website Link </span>
                    </template>
                  </v-text-field>
                </v-list>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-list dense subheader class="my-0 py-0">
                  <!-- <div style="width: 100%" class="text-center">
                     -->
                     <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Level
                      </v-list-item-title>
                    </v-list-item-content>
                      <v-list-item-action>
                        <v-btn-toggle
                    mandatory
                    outlined
                    v-model="product.level"
                    rounded
                    color="orange"
                  >
                  <v-btn small outlined value="SHIPMENT">
                      <v-icon
                        v-if="!$vuetify.breakpoint.mobile"
                        left
                        small
                        :color="
                          product.type == 'SHIPMENT' ? 'orange' : 'primaryText'
                        "
                        >directions_boat</v-icon
                      >
                      <span
                        style="
                          text-transform: none;
                          color: var(--v-primaryText-base);
                        "
                        >Shipment</span
                      >
                    </v-btn>
                    <v-btn small outlined value="CONTAINER">
                      <v-icon
                        v-if="!$vuetify.breakpoint.mobile"
                        left
                        small
                        :color="
                          product.type == 'CONTAINER' ? 'orange' : 'primaryText'
                        "
                        >widgets</v-icon
                      >
                      <span
                        style="
                          text-transform: none;
                          color: var(--v-primaryText-base);
                        "
                        >Container</span
                      >
                    </v-btn>
                   
                  </v-btn-toggle>
                      </v-list-item-action>
                      </v-list-item>
                     <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Type
                      </v-list-item-title>
                    </v-list-item-content>
                      <v-list-item-action>
                        <v-btn-toggle
                    mandatory
                    outlined
                    v-model="product.type"
                    rounded
                    color="blue"
                  >
                    <v-btn small outlined value="PRODUCT">
                      <v-icon
                        v-if="!$vuetify.breakpoint.mobile"
                        left
                        small
                        :color="
                          product.type == 'PRODUCT' ? 'primary' : 'primaryText'
                        "
                        >category</v-icon
                      >
                      <span
                        style="
                          text-transform: none;
                          color: var(--v-primaryText-base);
                        "
                        >Product</span
                      >
                    </v-btn>
                    <v-btn small outlined value="SERVICE">
                      <v-icon
                        v-if="!$vuetify.breakpoint.mobile"
                        left
                        small
                        :color="
                          product.type == 'SERVICE' ? 'primary' : 'primaryText'
                        "
                        >engineering</v-icon
                      >
                      <span
                        style="
                          text-transform: none;
                          color: var(--v-primaryText-base);
                        "
                        >Service</span
                      >
                    </v-btn>
                  </v-btn-toggle>
                      </v-list-item-action>
                      </v-list-item>
                  <!-- </div> -->
                  
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small>image</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Product Image
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            tile
                            v-on="on"
                            size="60"
                            :color="
                              product.image
                                ? 'transparent'
                                : 'var(--v-background-base)'
                            "
                            class="pa-1"
                            :loading="loadingImage"
                            :style="{
                              border:
                                product.image || product.imageData
                                  ? 'none'
                                  : '1px dotted grey',
                              cursor: 'pointer',
                            }"
                            @click="openFileExplorer('image')"
                          >
                            <v-img
                              v-if="product.imageData"
                              :src="product.imageData.data"
                              contain
                            >
                            </v-img>
                            <v-img
                              v-else-if="product.image"
                              :src="product.image"
                              contain
                            >
                            </v-img>
                            <span v-else style="font-size: 12px"
                              ><v-icon>add</v-icon></span
                            >
                          </v-avatar>
                        </template>
                        <span v-if="product.image">Edit Image</span
                        ><span v-else>Add Product Image</span>
                      </v-tooltip>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small>image</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Product Icon
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            tile
                            v-on="on"
                            size="60"
                            :color="
                              product.image
                                ? 'transparent'
                                : 'var(--v-background-base)'
                            "
                            class="pa-1"
                            :loading="loadingImage"
                            :style="{
                              border:
                                product.icon || product.iconData
                                  ? 'none'
                                  : '1px dotted grey',
                              cursor: 'pointer',
                            }"
                            @click="openFileExplorer('icon')"
                          >
                            <v-img
                              v-if="product.iconData"
                              :src="product.iconData.data"
                              contain
                            >
                            </v-img>
                            <v-img
                              v-else-if="product.icon"
                              :src="product.icon"
                              contain
                            >
                            </v-img>
                            <span v-else style="font-size: 12px"
                              ><v-icon>add</v-icon></span
                            >
                          </v-avatar>
                        </template>
                        <span v-if="product.image">Edit Image</span
                        ><span v-else>Add Product Icon</span>
                      </v-tooltip>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon small>image</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 13px">
                        Product Icon
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            tile
                            v-on="on"
                            size="60"
                            :color="
                              product.icon
                                ? 'transparent'
                                : 'var(--v-background-base)'
                            "
                            class="pa-1"
                            :loading="loadingIcon"
                            :style="{
                              border: product.icon
                                ? 'none'
                                : '1px dotted grey',
                              cursor: 'pointer',
                            }"
                            @click="openFileExplorer('icon')"
                          >
                            <v-img
                              v-if="product.icon"
                              :src="product.icon"
                              height="100px"
                              contain
                            >
                            </v-img>
                            <span v-else style="font-size: 12px"
                              ><v-icon>add</v-icon></span
                            >
                          </v-avatar>
                        </template>
                        <span v-if="product.image">Edit Icon</span
                        ><span v-else>Add Product Icon</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item> -->
                </v-list>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-textarea
                  dense
                  outlined
                  v-model="product.description"
                  hide-details
                  class="my-1"
                  clearable
                  auto-grow
                  counter
                >
                  <template v-slot:label>
                    <span style="font-size: 13px"> Description </span>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <input
      id="imageUpload"
      type="file"
      hidden
      accept="image/jpeg, image/jpg, image/png, image/svg+xml"
      @change="imageChange($event)"
    />
    <input
      id="iconUpload"
      type="file"
      hidden
      accept="image/jpeg, image/jpg, image/png, image/svg+xml"
      @change="iconChange($event)"
    />
  </div>
</template>
    <script>
    /* eslint-disable vue/no-unused-components */
import ActionPanel from "./ProductTableComponents/ActionPanel.vue";

import { AgGridVue } from "ag-grid-vue";
import Level  from "./ProductTableComponents/Level.vue"; 
import Type  from "./ProductTableComponents/Type.vue"; 
export default {
  components: {
    ActionPanel,
    AgGridVue,
    Level,
    Type
  },
  data: () => ({
    bundles: [],
    categories: [],
    columns: [
    {
        headerName: "Type",
        field: "type",
        cellRenderer: 'Type'
      },
     
      {
        headerName: "Code",
        field: "code",
      },
      {
        headerName: "Name",
        field: "name",
      },
      {
        headerName: "Category",
        field: "productServiceCategory.name"
      },
      {
        headerName: "Subcategory",
        field: "productServiceSubcategory.name"

      },
      {
        headerName: "Level",
        field: "level",
        cellRenderer: 'Level'
      },
      {
        headerName: "Description",
        field: "description",
        wrapText: true,
        autoHeight: true,
        cellStyle: {
          'lineHeight': '14px',
          color: 'grey'
        }
      },
      
    ],
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        flex: 1,
        minWidth: 100,
        cellClass: "d-flex align-center",
        cellStyle: { fontSize: "11px" },
      },
    },
    deletingProduct: false,
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    initialState: {},
    loading: true,
    loadingIcon: false,
    loadingImage: false,
    productServices: [],
    product: {},
    productModal: false,
    savingProduct: false,
    search: null,
    searchTimeout: null,
    sidebar: {},
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  created() {
    if (
      localStorage["productTableState_" + this.$store.state.currentOrg.id]
    ) {
      let state = JSON.parse(localStorage["productTableState_" + this.$store.state.currentOrg.id]);
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
    this.load();
    this.sidebar = {
      toolPanels: [
        {
          id: "actions",
          labelDefault: "Actions",
          labelKey: "actions",
          iconKey: "chart",
          toolPanel: "ActionPanel",
          minWidth: 300,
          maxWidth: 500,
          width: 300,
          toolPanelParams: {
            exportExcel: () => {
              this.gridApi.exportDataAsExcel({
                fileName :'ProductServices_'+new Date().getTime()+'.xlsx'}
              );
            },
            exportCSV: () => {
              this.gridApi.exportDataAsCsv({
                fileName: 'ProductServices_'+new Date().getTime()+'.csv'
              }
              );
            },
          },
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      defaultToolPanel: "ActionPanel",
    };
  },
  methods: {
    addProduct() {
      this.product = {
        level: 'SHIPMENT',
        type: "PRODUCT",
        code: null,
        name: null,
        description: null,
        url: null,
        image: null,
        icon: null,
      };
      this.productModal = true;
    },
    deleteProduct(item) {
      this.$confirm(
        "Are you sure you want to delete this product?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      ).then(async () => {
        this.deletingProduct = true;
        await this.$API.updateProductService({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
        this.$message.success("Product deleted successfully!");
        let findProduct = this.productServices.findIndex(
          (x) => x.id == item.id
        );
        this.productModal = false;
        this.deletingProduct = false;
        this.productServices.splice(findProduct, 1);
        this.gridApi.setRowData(this.productServices);
      });
    },
    editProduct(item) {
      this.product = item;
      this.productModal = true;
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    filteredSubcategories(){
      let result = []
      if(this.product && this.product.productServiceCategoryId){
        result = this.categories.find(x=>x.id == this.product.productServiceCategoryId)
        result = result? result.productServiceSubcategories:[]
      }
      return result
    },
    async getCategories(){
            this.categories = await this.$API.getProductCategories()
        },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "Edit Product",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">edit</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.editProduct(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          {
            name: "Delete Product",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">delete</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.deleteProduct(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      // this.load();
    },
    onStateUpdated(state) {
        localStorage.setItem(
        "productTableState_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );    
    },
    async load() {
      this.loading = true;
      this.getCategories()
      this.bundles = await this.$API.getProductBundles();
      this.productServices = await this.$API.getProductServices();
      this.loading = false
      if(this.gridApi){
        this.gridApi.setRowData(this.productServices)
      }
    },
    openFileExplorer(val) {
      if (val == "image") {
        document.getElementById("imageUpload").click();
      }
      if (val == "icon") {
        document.getElementById("iconUpload").click();
      }
    },
    async iconChange(x) {
      let file = x.target.files[0];
      this.loadingIcon = true;
      let url = await URL.createObjectURL(x.target.files[0]);
      this.toDataUrl(url, (data) => {
        this.product.iconData = {
          data: data,
          type: file.type,
          name: file.name,
        };
        this.loadingIcon = false;
      });
    },
    async imageChange(x) {
      let file = x.target.files[0];
      this.loadingImage = true;
      let url = await URL.createObjectURL(x.target.files[0]);
      this.toDataUrl(url, (data) => {
        this.product.imageData = {
          data: data,
          type: file.type,
          name: file.name,
        };
        this.loadingImage = false;
      });
    },
    async saveProduct() {
      this.savingProduct = true;
      if (this.product.id) {
        await this.$API.updateProductService(this.product);
      } else {
        await this.$API.createProductService(this.product);
      }
      this.productModal = false;
      this.savingProduct = false;
      this.load();
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
  },
};
</script>
<style scoped>
.infoCards {
  background: var(--v-greyRaised-lighten1) !important;
  contain: content;
  border-radius: 10px;
}
.innerContent {
  max-height: calc(100vh - 36px - 30px) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: auto;
}
</style>