<template>
    <div v-if="type">
            <v-icon small class="mr-1" :color="type.color">{{type.icon}}</v-icon>
            {{ $Format.capitalizeFirstLetter(type.value) }}
    </div>
</template>
<script>
export default {
    data:()=>({
        item: null,
        options: [
            {value: 'PRODUCT', icon: 'category', color: 'cyan'},
            {value: 'SERVICE', icon: 'category', color: 'amber'},
        ],
        type: null
    }),
    mounted(){
        this.item = this.params.value
        this.type = this.getType(this.item)
    },
    methods: {
        getType(type) {
            let find = this.options.find(x => x.value == type)
            return find
        }
    }
}
</script>